import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import OrchidImg from "../images/orchid1.jpg";
import styled from "styled-components";
import Container from "../components/container";
import { Link } from "gatsby";
import {
  Booking,
  BookingContainer,
  GreenButton,
  HeroContainer,
  IndexHeroTitle,
  IntroContainer,
  Links,
  MainTitle,
  OneColumn,
  PhoneNumber,
  Review,
  ReviewContainer,
  RosesContainer,
  RosesImg,
  RoundedImg,
  TitleContainer,
  TwoColumns,
  GalleryImg,
  GalleryWrapper,
} from "../components/styles/styles";
import Navbar from "../components/navbar";
import { GatsbyImage } from "gatsby-plugin-image";
import SEO from "../components/seo";

const RosesBigImg = styled(GatsbyImage)`
  position: absolute;
  bottom: 0px;
  right: -80px;
  width: 700px;
  transform: scale(1.5) rotate(160deg);
  z-index: 1;

  @media (min-width: 550px) {
    transform: scale(1.25) rotate(160deg);
  }

  @media (max-width: 550px) {
    display: none;
  }
`;

const IndexPage = ({ data }) => {
  return (
    <Layout>
      <SEO />
      <main>
        <IntroContainer>
          <Navbar />
          <Container>
            <HeroContainer>
              <IndexHeroTitle>
                Ellies. Din lokala frisör. Välkommen!
              </IndexHeroTitle>
              <BookingContainer>
                <GreenButton>Boka nu</GreenButton>
                <PhoneNumber href="tel:+4670-670 70 04">
                  070-670 70 04
                </PhoneNumber>
              </BookingContainer>
            </HeroContainer>
          </Container>
        </IntroContainer>

        <Container>
          <TwoColumns className="section">
            <div>
              <h2>Hej!</h2>
              <h3>Välkommen till salongen omfångad av blomster.</h3>
              <p>
                Ellinore heter jag, frisören som håller till på Östersundsvägen
                25 i Lit.
              </p>
              <p>
                Jag delar lokal med min kära storasyster som är florist vilket
                skapar ett härligt omfång av växter för dig som kommer till mig.
              </p>
              <p>
                Hos mig ska du kunna känna dig trygg och bekväm. Jag lyssnar
                noga på dina önskemål och ger råd och tips som passar just dig.
              </p>
              <p>
                Är du osäker på vad du vill ha eller vilken typ av behandling du
                ska boka? Kontakta mig så hjälper jag dig.
              </p>
              <p>Hoppas vi ses!</p>
              {/* <p>
                Titta gärna också i mitt <Link to="/galleri">galleri</Link> för
                inspiration.
              </p> */}
              <GreenButton>Boka nu</GreenButton>
            </div>
            <div>
              <RoundedImg
                alt=""
                image={data.selfie.image.gatsbyImageData}
              ></RoundedImg>
            </div>
          </TwoColumns>
        </Container>
        <OneColumn className="section">
          <Container>
            <h2>Din trygga &amp; glada frisör</h2>
            <p className="title-font">
            Vår salong erbjuder en lyxig och avslappnad atmosfär där du kan känna dig bekväm och nöjd. 
            Vi fokuserar på högkvalitativa klippningar och färgningar som får dig att känna dig fantastisk varje gång du lämnar oss.
            </p>
            <p className="title-font">Kom in och låt oss ta hand om dig!</p>
          </Container>
        </OneColumn>
      </main>
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query HomePageQuery {
    contentfulGallery {
      images {
        gatsbyImageData(
          layout: CONSTRAINED
          placeholder: BLURRED
          width: 800
          quality: 100
        )
      }
    }
    roses: contentfulImage(title: { eq: "Roses" }) {
      image {
        gatsbyImageData(
          layout: CONSTRAINED
          placeholder: BLURRED
          width: 800
          quality: 100
        )
      }
    }
    rosesBig: contentfulImage(title: { eq: "Roses big" }) {
      image {
        gatsbyImageData(
          layout: CONSTRAINED
          placeholder: BLURRED
          width: 800
          quality: 100
        )
      }
    }
    selfie: contentfulImage(title: { eq: "Selfie" }) {
      image {
        gatsbyImageData(
          layout: CONSTRAINED
          placeholder: BLURRED
          width: 800
          quality: 100
        )
      }
    }
  }
`;
